import React from 'react'

import { addNotification, Button, Notifications } from '@te-digi/styleguide'

const PatternExampleTallenna = () => {
  const onModalOpenClick1 = () => {
    addNotification('Tiedot tallennettiin onnistuneesti.', {
      status: 'success'
    })
  }

  return (
    <>
      <Button
        color="primary"
        onClick={onModalOpenClick1}
      >
        Tallenna
      </Button>
      <Notifications />
    </>
  )
}

export { PatternExampleTallenna }
